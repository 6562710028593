import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import { getMenuItems } from '../../selectors/menu';

const RegistrationOk = props => {
  const { firstName, lastName, menuItems } = props;

  let mainFormTabIndex = _.findIndex(menuItems, item => item.mainForm === true);
  mainFormTabIndex = mainFormTabIndex < 0 ? 0 : mainFormTabIndex;
  const mainFormTab = {
    ...menuItems[mainFormTabIndex],
    index: mainFormTabIndex,
  };

  const style = {
    margin: 12,
    align: 'center',
  };
  return (
    <div>
      <h3>
        <FormattedMessage
          id="registration.tankyou"
          defaultMessage="Thank you {firstName} {lastName}"
          values={{ firstName, lastName }}
        />
      </h3>
      <p>
        Nous avons bien noté que vous ne participerez pas.
      </p>
      <Button
        variant="contained"
        color="primary"
        style={style}
        component={Link}
        to={`${mainFormTab.href}/modify`}
      >
        <FormattedMessage
          id="registration.button.modify"
          defaultMessage="Modify your registration"
        />
      </Button>
    </div>
  );
};

RegistrationOk.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ profile }) => ({
  firstName: profile.firstName,
  lastName: profile.lastName,
  menuItems: getMenuItems({ profile }),
});

export default connect(mapStateToProps)(RegistrationOk);
