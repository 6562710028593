import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import _ from 'lodash';
import ReactGA from 'react-ga';

import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CONFIG from '../../config/config.yaml';
import FORMS from '../../config/forms.yaml';

import Recipients from './MagicLink/Recipients';
import AdditionalFields from './MagicLink/AdditionalFields';

const MAILING_URL = 'https://api-v2.phileog.com/2.0/mailing';

export const BASE_RECIPIENT = {
  email: '',
  emailConfirmation: '',
  firstName: '',
  lastName: '',
};

const { resaKey, invitationWithText } = CONFIG;

class MagicLink extends React.Component {
  static propTypes = {
    asAdmin: PropTypes.bool,
    accept: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    asAdmin: false,
    accept: false,
  };

  static defaultState = {
    invitationText: '',
    recipients: [BASE_RECIPIENT],
    sendMail: true,
    mailSent: false,
  };

  constructor(props) {
    super(props);
    this.state = MagicLink.defaultState;
  }

  componentDidMount() {
    this.setDefaultState();
  }

  get hasSecondStep() {
    const { asAdmin } = this.props;
    return asAdmin && !_.isNil(FORMS.inscription);
  }

  get mustConfirmEmail() {
    const { asAdmin } = this.props;
    return !asAdmin;
  }

  setDefaultState() {
    this.setState(MagicLink.defaultState);
  }

  handleSetInvitationText = invitationText => {
    this.setState({ invitationText });
  };

  handleSetRecipient = (index, recipient = BASE_RECIPIENT) => {
    const { recipients } = this.state;
    const newRecipients = [...recipients];
    if (_.isNil(index)) {
      newRecipients.push(recipient);
    } else if (_.isNil(recipient)) {
      newRecipients.splice(index, 1);
    } else {
      newRecipients[index] = recipient;
    }
    this.setState({ recipients: newRecipients });
  };

  handleToggleSendMail = () => {
    const { sendMail } = this.state;
    this.setState({ sendMail: !sendMail });
  };

  /**
   *  Click submit
   *    for not-admin-submitting
   *    => No form data
   */
  handleClickSubmit = () => {
    this.handleSubmit();
  };

  /**
   *  @param {Object} rawValues form data if admin submit (or empty)
   */
  handleSubmit = rawValues => {
    const { recipients, invitationText } = this.state;

    let data;
    if (!_.isNil(rawValues)) {
      const values = { ...rawValues };
      // flatten roles - TODO support more types ?
      if (values.roles && Array.isArray(values.roles)) {
        values.roles = values.roles.join(',');
      }
      data = _.omit(values || {}, ['email', 'emails', 'invitationText']);
    } else {
      // autoregister => set default value
      const { template, entry } = FORMS.registerDefaults || {};
      data = template ? FORMS.templateMapping[template][entry].value : {};
    }

    this.sendMagicLink(recipients, invitationText, data);
  };

  handleReset = () => {
    const { dispatch } = this.props;
    dispatch(reset('invitationForm'));
    this.setDefaultState();
  };

  isValid() {
    const { recipients } = this.state;
    return (
      recipients.length > 0 &&
      !!recipients.reduce((res, { email, emailError, emailConfirmation }) => {
        const emailIsValid = email && _.isNil(emailError);
        const emailConfirmationIsValid =
          !this.mustConfirmEmail || email === emailConfirmation;
        return res && emailIsValid && emailConfirmationIsValid;
      }, true)
    );
  }

  sendMagicLink(emails, invitationText, data) {
    const { asAdmin } = this.props;
    const { sendMail } = this.state;
    let mailing = 'none';
    if (sendMail) {
      mailing = asAdmin ? 'invitation' : 'magic';
    }
    this.sendMailing(emails, mailing, invitationText, data).then(response => {
      if (response.status === 200) {
        this.setState({ mailSent: true });
      }
      if (mailing === 'magic') {
        ReactGA.event({
          category: 'Submit',
          action: 'magic',
          value: 1, // data.presence ? 10 : 1, // autoregister value = 1 - must be int !!
        });
      }
    });
  }

  sendMailing(recipients, mailing = 'magic', invitationText, fields = {}) {
    const { asAdmin } = this.props;
    const data = {
      resaKey,
      mailing,
      dest: recipients.map(email => ({
        ...email,
        mail: email.email,
        email: undefined, // will be stripped by JSON.stringify
        emailConfirmation: undefined, // delete too
      })),
      magic: asAdmin, // force autoregister, TODO: sign request https://github.com/kndt84/aws-api-gateway-client !!
      data: {
        ...fields,
        // complex types must be serialized here - TODO support more types
        roles: Array.isArray(fields.roles)
          ? fields.roles.join(',')
          : fields.roles,
      },
    };
    if (invitationText) {
      data.data.custom = invitationText.replace(/\n/g, '<br/>'); // use as {{{custom}}} in email template
    }
    return fetch(MAILING_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data, null, 2),
    });
  }

  render() {
    const { asAdmin, accept } = this.props;
    const { recipients, invitationText, sendMail, mailSent } = this.state;

    if (mailSent) {
      return (
        <p style={{ fontSize: '16px' }}>
          {asAdmin ? (
            <>
              <FormattedMessage
                id="invitation.sent.to"
                defaultMessage="Invitation sent"
                values={{ nbEmails: recipients.length }}
              />
              <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={{ margin: '0 10px' }}
                  variant="contained"
                  color="primary"
                  onClick={this.handleReset}
                >
                  <FormattedMessage
                    id="form.inviteReset"
                    defaultMessage="Nouvelle invitation"
                  />
                </Button>
              </span>
            </>
          ) : (
            <span>
              <h3>Votre inscription est presque terminée.</h3> 
              D’ici quelques minutes, vous allez recevoir un mail qui vous permettra d’accéder au formulaire d’inscription.  <b>À tout de suite !</b>
            </span>
          )}
        </p>
      );
    }

    const buttonLabel = asAdmin ? (
      <FormattedMessage
        id="invitation.button.send"
        defaultMessage="Send the invitation"
      />
    ) : (
      <FormattedMessage
        id="invitation.button.receive"
        defaultMessage="CONFIRMER MON EMAIL"
      />
    );

    return (
      <div style={{ fontSize: '16px' }}>
        {asAdmin ? (
          <h3>Invitation</h3>
        ) : (
          <div>
            <p>
              {/* <FormattedMessage
                id="invitation.message.register"
                defaultMessage="Enter your email to receive your personal invitation."
              /> */}
              Merci de confirmer votre adresse e-mail. Vous recevrez une invitation personnalisée pour finaliser votre inscription à l’opération 
              « La preuve par 9 » de SNCF Réseau et ses partenaires.
            </p>
          </div>
        )}
        <Recipients
          invitationText={invitationText}
          onSetInvitationText={this.handleSetInvitationText}
          recipients={recipients}
          handleSetRecipient={this.handleSetRecipient}
          asAdmin={asAdmin}
          mustConfirmEmail={this.mustConfirmEmail}
          invitationWithText={invitationWithText}
        />
        {asAdmin && (
          <FormControlLabel
            control={
              <Checkbox
                checked={sendMail}
                onClick={this.handleToggleSendMail}
              />
            }
            label="Envoyer un mail au destinataires"
          />
        )}
        {this.hasSecondStep ? (
          <AdditionalFields
            buttonLabel={buttonLabel}
            canSubmit={(accept || asAdmin) && this.isValid()}
            handleSubmit={this.handleSubmit}
          />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              onClick={this.handleClickSubmit}
              style={{ margin: '0 10px' }}
              variant="contained"
              color="primary"
              disabled={!this.isValid()}
            >
              {buttonLabel}
            </Button>
          </div>
        )}
        {!asAdmin && <p>&nbsp;</p>}
      </div>
    );
  }
}

export default connect()(MagicLink);
