// eslint-disable-next-line
import '!style-loader!../../tools/config-css-loader.js!yaml-loader!../config/customization.yaml';
// move to webpack alias ?

import kit from './kit.md';
import welcome from './welcome.md';
import contact from './contact.md';
import program from './program.md';
import closed from './closed.md';
import dataprivacy from './dataprivacy.md';
import faq from './faq.md';

export default {
  kit,
  program,
  welcome,
  closed,
  contact,
  dataprivacy,
  faq,
};
