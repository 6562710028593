import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';


import { getMenuItems } from '../../selectors/menu';

const RegistrationOk = props => {
  const { menuItems } = props;

  let mainFormTabIndex = _.findIndex(menuItems, item => item.mainForm === true);
  mainFormTabIndex = mainFormTabIndex < 0 ? 0 : mainFormTabIndex;
  const mainFormTab = {
    ...menuItems[mainFormTabIndex],
    index: mainFormTabIndex,
  };

  const style = {
    margin: 12,
    align: 'center',
  };
  return (
    <div>

        <h4>Merci d’avoir complété le parcours digital avec vos équipes.</h4>
       <p> Nous vous adresserons une synthèse pour mettre en commun les réflexions de toutes les équipes SNCF Réseau et partenaires qui auront participé à l’opération de prévention sur les engins rail route.
       </p>

      <Button
        variant="contained"
        color="primary"
        style={style}
        component={Link}
        to={`${mainFormTab.href}/reset`}
      >
       FAIRE UN NOUVEAU PARCOURS  
  
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={style}
        component={Link}
        to={`${mainFormTab.href}/modify`}
      >
       REPRENDRE LE PARCOURS   
      </Button>

    </div>
  );
};

RegistrationOk.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ profile }) => ({
  menuItems: getMenuItems({ profile }),
});

export default connect(mapStateToProps)(RegistrationOk);
